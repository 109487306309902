import { Card, Tab, Tabs } from 'react-bootstrap';

export function PrettyCard({ title, children, footer }) {
  return (
    <Card>
      <Card.Header>
        {title}
      </Card.Header>
      <Card.Body>
        {children}
      </Card.Body>
      {footer && (
        <Card.Footer>
          {footer}
        </Card.Footer>
      )}
    </Card>
  );
}

export function NavigatableTabs({ tabs }) {
  const TABNAME = 0;
  const TABCOMPONENT = 1;
  const nothingToShow = !tabs;
  return (
    <>
      {
            nothingToShow ? <p className="lead">Nothing to show</p>
              : (
                <Tabs
                  defaultActiveKey={1}
                >
                  {Object.entries(tabs).map(
                    (pair, index) => (
                      <Tab key={pair[TABNAME]} eventKey={index + 1} title={pair[TABNAME]}>
                        <div className="border border-top-0 p-3">
                          {pair[TABCOMPONENT]}
                        </div>
                      </Tab>
                    ),
                  )}
                </Tabs>
              )
        }
    </>
  );
}
