import { Button } from 'react-bootstrap';

/**
 *
 * @param loading Whether loading should be indicated
 */
export default function ({
  loading, textForSubmit = 'Next', cancelButtonExists, onCancel, textForCancel = 'Back',
}) {
  return (
    loading
      ? <div>Loading</div>
      : (
        <div className="text-end">
          <div className="d-inline">
            {cancelButtonExists && (
            <Button onClick={onCancel} variant="secondary" className="me-3 px-4">
              {textForCancel}
            </Button>
            )}
            <Button type="submit" className={`ml-3 px-4 ${!cancelButtonExists && 'w-100 m-0'}`}>
              {textForSubmit}
            </Button>
          </div>
        </div>
      )
  );
}
