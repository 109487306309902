import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';

const pageMetadata = {
  titleLH: 'Payment',
  titleRH: 'Failed',
  body: 'A system exception has occurred. This may be due to invalid data, your payment or receipt has timed out, or the system may be temporarily unavailable. Please retry your registration.',
};

export default function () {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return (
    <Link to="/">
      <Button>Back to Home</Button>
    </Link>
  );
}
