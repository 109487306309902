import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const aDefaultStyle = {
  fontSize: '0.75em',
  display: 'inline-block',
  lineHeight: '1.4em',
  width: '1.4em',
  height: '1.4em',
  backgroundColor: 'hsl(202, 54%, 43%)',
  textAlign: 'center',
  borderRadius: '50%',
  cursor: 'pointer',
  color: 'white',
  fontWeight: 'bold',
  margin: '0 0.3em',
  boxShadow: '1px 1px 2px grey',
};

const spanDefaultStyle = {
  position: 'relative',
};

export default function Tooltip({
  children, tip, aStyle, spanStyle,
}) {
  tippy('#tooltip', {
    content: tip,
    theme: 'light-border',
  });

  const aFinalStyle = { ...aDefaultStyle, ...aStyle };
  const spanFinalStyle = { ...spanDefaultStyle, ...spanStyle };

  return (
    <a id="tooltip" style={aFinalStyle}>
      <span style={spanFinalStyle}>{children ?? '?'}</span>
    </a>
  );
}
