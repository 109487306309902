import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ErrorPlaceholder from '../components/common/ErrorPlaceholder';
import AdminPane from '../components/dashboard/admin/AdminDashboard';
import StudentDashboard from '../components/dashboard/student/StudentDashboard';
import TeacherDashboard from '../components/dashboard/teacher/TeacherDashboard';
import EventList from '../components/events/EventList';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext, defaultPageMetadata } from '../context/PageContextProvider';
import {
  ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE,
} from '../utils/roleComputation';
import { authenticate, authenticateAsOtherUser } from '../services/authroutes';
import useWithLoading from '../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import Loading from '../components/common/Loading';
import { SessionContext } from '../context/SessionContextProvider';
import AdminBackdoor from '../components/dashboard/admin/AdminBackdoor';
import DisplayForRole from '../components/common/DisplayForRole';

function DashboardPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    const pageMetadata = { ...defaultPageMetadata };
    if (authRetrievedProfile) {
      pageMetadata.titleLH = 'Welcome';
      pageMetadata.titleRH = authRetrievedProfile?.name;
    }
    setPageMetadata(pageMetadata);
  }, []);

  function UserView() {
    if (!authRetrievedProfile) {
      return <EventList />;
    }

    const { role } = authRetrievedProfile;

    if (role === ADMIN_ROLE) {
      return <AdminPane />;
    } if (role === STUDENT_ROLE) {
      return (<StudentDashboard />);
    } if (role === TEACHER_ROLE) {
      return <TeacherDashboard />;
    }
    return <ErrorPlaceholder />;
  }

  return (
    <>
      <AdminBackdoor />
      <UserView />
    </>
  );
}

export default DashboardPage;
