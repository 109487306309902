function PdfIframe({ pdfUrl }) {
  return <iframe style={{ flexGrow: 1, width: '120px' }} src={pdfUrl} />;
}

export function PdfDisplayer({ pdfUrls, className = '' }) {
  const pdfUrlsArray = Array.isArray(pdfUrls) ? pdfUrls : [pdfUrls];

  return (
    <div
      className={`d-flex ${className} gap-3 overflow-auto`}
    >
      {pdfUrlsArray.map((pdfUrl) => <PdfIframe pdfUrl={pdfUrl} />)}
    </div>
  );
}
