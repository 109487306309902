import { createContext, useContext } from 'react';
import createPersistedState from 'use-persisted-state';
import { SessionContext } from './SessionContextProvider';

const usePersistedAuthProfileState = createPersistedState('authProfile');

const AuthContext = createContext({
  onLogOut: async () => {
  },
  setAuthRetrievedProfile: (profile) => {},
  authRetrievedProfile: null,
});

function AuthContextProvider({ children }) {
  /**
   * authRetrievedProfile is effectively a login state (containing email, code, etc)
   * and it is stored in the browser's localStorage.
   */
  const [authRetrievedProfile, setAuthRetrievedProfile] = usePersistedAuthProfileState();
  const { clearSession } = useContext(SessionContext);

  const onLogOut = async () => {
    setAuthRetrievedProfile(null);
    clearSession();
  };

  return (
    <AuthContext.Provider value={{
      authRetrievedProfile, setAuthRetrievedProfile, onLogOut,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export {
  AuthContext,
  AuthContextProvider,
};
