import { Accordion } from 'react-bootstrap';

export default function FAQAccordion() {
  return (
    <>
      <small className="fw-bold text-muted">FAQ</small>
      <Accordion>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Does the round 1 registration fee include the fee for finals?</Accordion.Header>
          <Accordion.Body>
            No, they are separate. Students eligible for finals will
            receive an email after round 1 for a separate finals registration. Students may also need to arrange
            their own accommodation if they are traveling to compete in the finals.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Can the student choose which island to sit their finals in?</Accordion.Header>
          <Accordion.Body>
            No, students must sit their finals on the island that their school belongs to.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Is NZPMC GST registered?</Accordion.Header>
          <Accordion.Body>
            No, NZPMC is not GST-registered.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>Can the school pay the payment in bulk?</Accordion.Header>
          <Accordion.Body>
            No, students have options to pay through PoliPay (accepts major NZ banks) and Stripe (accepts debit/credit cards).
            Teachers are able to retrieve the most up to date spreadsheet of all the students registered
            under their email at any time from the dashboard.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>Is there financial support for registration fees?</Accordion.Header>
          <Accordion.Body>
            We apply discount based on EQI index of school. See
            {' '}
            <a href="https://butternut-pixie-b59.notion.site/Discount-on-Competition-Fee-c1a891c4e9a4424488348381526a4ab5">EQI discount</a>
            {' '}
            for pricing policy.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
