import { createContext, useState } from 'react';

const PageContext = createContext({
  pageMetadata: {},
  setPageMetadata: (metadata) => {},
  useDefault: () => {},
});

export const defaultPageMetadata = {
  titleLH: 'Welcome to',
  titleRH: 'NZPMC',
  body: 'All-in-one portal for NZPMC event registration, payment, result dashboard.',
};

function PageContextProvider({ children }) {
  const [pageMetadata, setPageMetadata] = useState(defaultPageMetadata);

  const useDefault = () => setPageMetadata(defaultPageMetadata);

  return (
    <PageContext.Provider value={{ pageMetadata, setPageMetadata, useDefault }}>
      {children}
    </PageContext.Provider>
  );
}

export {
  PageContext,
  PageContextProvider,
};
