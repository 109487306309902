import { Alert } from 'react-bootstrap';

// Error can be either an error or text
export default function ErrorPlaceholder({ error }) {
  const defaultMessage = (
    <>
      Oops! That's our bad.
      <br />
      Please try signing out and signing back in. If the problem persists, email contact@nzpmc.com for support.
    </>
  );

  return (
    <Alert variant="info">
      <h1>505</h1>
      {error?.message ?? error ?? defaultMessage}
    </Alert>
  );
}
