import { useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { SessionContext } from '../../../context/SessionContextProvider';

export default function CheckBoxInput({ label, field, required = false }) {
  const { handleSessionChange } = useContext(SessionContext);

  return (
    <Form.Check
      type="checkbox"
      label={label}
      onChange={handleSessionChange(field, true)}
      required={required}
    />
  );
}
