import { useCallback } from 'react';
import styles from './RichTextMenu.module.css';

export default function RichTextMenu({ editor }) {
  const ask = useCallback(
    (callback, displayText) => () => {
      const res = window.prompt(displayText ?? 'Content:');
      if (res) {
        callback(res);
      }
    },
    [editor],
  );

  const buttons = [
    {
      content: 'h1',
      onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run(),
      isActive: () => editor.isActive('heading', { level: 1 }),
    },
    {
      content: 'h2',
      onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run(),
      isActive: () => editor.isActive('heading', { level: 2 }),
    },
    {
      content: 'h3',
      onClick: () => editor.chain().focus().toggleHeading({ level: 3 }).run(),
      isActive: () => editor.isActive('heading', { level: 3 }),
    },
    {
      content: 'p',
      onClick: () => editor.chain().focus().setParagraph().run(),
      isActive: () => editor.isActive('paragraph'),
    },
    {
      content: '<',
      onClick: () => editor.chain().focus().setTextAlign('left').run(),
      isActive: () => editor.isActive({ textAlign: 'left' }),
    },
    {
      content: '-',
      onClick: () => editor.chain().focus().setTextAlign('center').run(),
      isActive: () => editor.isActive({ textAlign: 'center' }),
    },
    {
      content: '>',
      onClick: () => editor.chain().focus().setTextAlign('right').run(),
      isActive: () => editor.isActive({ textAlign: 'right' }),
    },
    {
      content: '=',
      onClick: () => editor.chain().focus().setTextAlign('justify').run(),
      isActive: () => editor.isActive({ textAlign: 'justify' }),
    },
    {
      content: (
        <span key="sub">
          x
          <sub>1</sub>
        </span>),
      onClick: () => editor.chain().focus().toggleSubscript().run(),
      isActive: () => editor.isActive('subscript'),
    },
    {
      content: (
        <span key="sup">
          x
          <sup>1</sup>
        </span>),
      onClick: () => editor.chain().focus().toggleSuperscript().run(),
      isActive: () => editor.isActive('superscript'),
    },
    {
      content: <b key="b">B</b>,
      onClick: () => editor.chain().focus().toggleBold().run(),
      isActive: () => editor.isActive('bold'),
    },
    {
      content: <i key="i">I</i>,
      onClick: () => editor.chain().focus().toggleItalic().run(),
      isActive: () => editor.isActive('italic'),
    },
    {
      content: <u key="u">U</u>,
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      isActive: () => editor.isActive('underline'),
    },
    {
      content: <s key="s">S</s>,
      onClick: () => editor.chain().focus().toggleStrike().run(),
      isActive: () => editor.isActive('strike'),
    },
    {
      content: <b key="color" style={{ color: editor.getAttributes('textStyle').color ?? 'red' }}>A</b>,
      onClick: ask((color) => {
        if (color === 'none') {
          editor.chain().focus().unsetColor().run();
        } else {
          editor.chain().focus().setColor(color).run();
        }
      }, "Colour: (e.g. 'red', '#ff0000', 'rgb(255, 0, 0)' OR 'none')"),
    },
    {
      content: <b key="highlight" style={{ padding: '0 5px', backgroundColor: editor.getAttributes('highlight').color ?? 'gold' }}>A</b>,
      onClick: ask((color) => {
        if (color === 'none') {
          editor.chain().focus().unsetHighlight().run();
        } else {
          editor.chain().focus().setHighlight({ color }).run();
        }
      }, "Colour: (e.g. 'red', '#ff0000', 'rgb(255, 0, 0)' OR 'none')"),
    },
    {
      content: '🖼️',
      onClick: ask((src) => editor.chain().focus().setImage({ src }).run(), 'Image URL:'),
    },
    {
      content: '❌',
      onClick: () => editor.chain().focus().setContent('').run(),
    },
  ];

  return (
    <div className="control-group">
      <div className={`button-group ${styles.menu}`}>
        {buttons.map((button) => (
          <button
            key={button.content.key || button.content}
            type="button"
            onClick={button.onClick}
            className={`${styles.button} ${button.isActive?.() ? styles.isActive : ''}`}
          >
            {button.content}
          </button>
        ))}
      </div>
    </div>
  );
}
