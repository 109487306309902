import { toNZTString } from './DateTimeUtils';

export const getFormattedNZTDateString = (date) => {
  const options = {
    // weekday: 'short',
    // year: 'numeric',
    // month: 'short',
    // day: 'numeric',
    timeZoneName: undefined,
  };
  return toNZTString(date, options);
};

export const getDateStringFromAnnouncement = (announcement) => {
  const hasNoDateToRender = !announcement?.scheduledDate && !announcement?.creationDate;
  if (hasNoDateToRender) return '';

  const isScheduled = !!announcement.scheduledDate;

  return isScheduled
    ? `Scheduled ${getFormattedNZTDateString(announcement.scheduledDate)}`
    : getFormattedNZTDateString(announcement.creationDate);
};

export const removeHtmlTags = (html) => {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
};
