import {
  useContext, useEffect, useRef, useState,
} from 'react';
import {
  Alert,
  Breadcrumb, Button, InputGroup,
} from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { useHistory, useParams } from 'react-router-dom';
import Comment from '../components/announcement/comment/Comment';
import Loading from '../components/common/Loading';
import { PdfDisplayer } from '../components/common/PdfComponents';
import RichTextDisplay from '../components/forms/common/richtext/RichTextDisplay';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import {
  addCommentToAnnouncement,
  getAnnouncementById,
  replyToComment,
} from '../services/announcement';
import { getDateStringFromAnnouncement } from '../utils/AnnouncementUtils';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';

const pageMetadata = {
  titleLH: 'Our',
  titleRH: 'Announcements',
  body: "Don't miss any announcements! You need to refresh the page to see new comments.",
};

export default function AnnouncementPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { setPageMetadata } = useContext(PageContext);
  const [loading, withLoading] = useWithLoading();
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { announcementId } = useParams();
  const [announcement, setAnnouncement] = useState();
  const history = useHistory();

  useEffect(() => {
    withLoading(
      () => getAnnouncementById(
        authRetrievedProfile?.email,
        authRetrievedProfile?.code,
        announcementId,
      ),
      (res) => setAnnouncement(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const addReply = async (parentComment, replyContent) => {
    if (!validateComment(replyContent)) return;

    const newReply = {
      // Create a temporary id for frontend rendering
      id: Date.now().toString(),
      name: authRetrievedProfile.name,
      user: authRetrievedProfile.email,
      content: replyContent,
      role: authRetrievedProfile.role,
      replies: [],
      creationDate: new Date(),
    };

    const parentAndReply = {
      parent: parentComment,
      reply: newReply,
    };

    // Update database with comment.
    await replyToComment(
      authRetrievedProfile?.email,
      authRetrievedProfile?.code,
      announcementId,
      parentAndReply,
    );
    setAnnouncement((currentAnnouncement) => updateAnnouncementWithReply(
      currentAnnouncement,
      parentComment.id,
      newReply,
    ));
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>
          Home
        </Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/announcements')}>
          Announcements
        </Breadcrumb.Item>
        <Breadcrumb.Item active>{announcement?.title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="border p-3">
        <Loading loading={loading}>
          <AnnouncementContent announcement={announcement} />
        </Loading>
        <hr />
        <CommentForm
          announcement={announcement}
          setAnnouncement={setAnnouncement}
        />
        <p className="lead fw-bold">
          {announcement?.comments
            && getNumberOfComments(announcement?.comments)}
          {' '}
          Comments
        </p>
        {announcement?.comments?.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            addReply={addReply}
            isUserLoggedIn={authRetrievedProfile}
          />
        ))}
      </div>
    </>
  );
}

function AnnouncementContent({ announcement }) {
  if (!announcement) return null;
  const body = announcement.body ?? '[No body specified]';
  return (
    <>
      <h1 className="display-6">{announcement.title ?? 'Untitled'}</h1>
      <p className="text-end">{getDateStringFromAnnouncement(announcement)}</p>
      {/* Key forces rerendering when content changes - required otherwise useEditor caches content */}
      <RichTextDisplay content={body} />
      <PdfDisplayer pdfUrls={announcement.attachmentURLs} className="mb-3" />
    </>
  );
}

function CommentForm({ announcement, setAnnouncement }) {
  const [comment, setComment] = useState();
  const { authRetrievedProfile } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();
  const ref = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();

    // Check that the comment is not empty
    if (!validateComment(comment)) return;

    const newComment = {
      user: authRetrievedProfile.email,
      name: authRetrievedProfile.name,
      content: comment,
      role: authRetrievedProfile.role,
      creationDate: new Date(),
    };

    try {
      withLoading(
        () => addCommentToAnnouncement(authRetrievedProfile.email, authRetrievedProfile.code, announcement.id, newComment),
        (res) => {
          setAnnouncement(res?.data);
          ref.current.value = '';
          setComment(null);
        },
        ALERT_ERROR_MESSAGE,
      );
    } catch (e) {
      alert(e);
    }
  };

  const handleChange = (e) => setComment(e?.target?.value);

  return (
    authRetrievedProfile
      ? (
        <InputGroup className="mb-3">
          <Form.Control ref={ref} placeholder="Enter your comment" onChange={handleChange} />
          <Button variant="outline-secondary" onClick={onSubmit}>
            Submit
          </Button>
        </InputGroup>
      )
      : (
        <Alert variant="dark">
          Comments are currently disabled. To add a comment, please login!
        </Alert>
      )
  );
}

function validateComment(comment) {
  if (!comment) {
    alert("You haven't written anything!");
    return false;
  }
  return true;
}

function updateAnnouncementWithReply(announcement, targetCommentId, newReply) {
  function updateComments(comments) {
    // Search the array of comments for the parent ID
    return comments.map((comment) => {
      if (comment.id === targetCommentId) {
        // We append the reply to the list of replies of that comment.
        return { ...comment, replies: [...comment.replies, newReply] };
      }
      // If not on this level, we recursively search the replies.
      return { ...comment, replies: updateComments(comment.replies) };
    });
  }
  // Return updated announcement with new comment.
  return {
    ...announcement,
    comments: updateComments(announcement.comments),
  };
}

function getNumberOfComments(comments) {
  let numComments = 0;
  // eslint-disable-next-line no-restricted-syntax
  for (const comment of comments) {
    numComments++;
    if (comment.replies && comment.replies.length > 0) {
      numComments += getNumberOfComments(comment.replies);
    }
  }
  return numComments;
}

export { AnnouncementContent, getNumberOfComments };

