import { Alert, Col, Row } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import studentsImage from '../../../images/students.JPG';
import useWithLoading from '../../../hooks/useWithLoading';
import { fetchPriceForEvent } from '../../../services/events';
import { AuthContext } from '../../../context/AuthContextProvider';
import Loading from '../../common/Loading';
import { ALERT_ERROR_MESSAGE } from '../../../utils/ResponseUtils';

function PaymentReview({ eventCode }) {
  const [loading, withLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);
  const [price, setPrice] = useState();

  useEffect(() => {
    const populatePriceField = () => withLoading(
      fetchPriceForEvent(authRetrievedProfile, eventCode),
      (res) => setPrice(res?.data),
      ALERT_ERROR_MESSAGE,
    );
    populatePriceField();
  }, []);

  return (
    <Row>
      <Row>
        <Col xs={5}>
          <img width="100%" src={studentsImage} />
        </Col>
        <Col xs={7}>
          <p>
            <Row>
              <Col xs={12}><h1 className="lead fw-bold mb-3">Payment Summary</h1></Col>
              <Col xs={8} className="fw-bold text-start">Items</Col>
              <Col xs={4} className="fw-bold text-start">Price</Col>
              <Col xs={8} className="text-start">
                Registration fee (
                <i>{eventCode}</i>
                )
              </Col>
              <Col xs={4} className="text-start">
                <Loading loading={loading}>
                  $
                  {price}
                  {' '}
                  NZD
                </Loading>
              </Col>
            </Row>
          </p>

          <Alert>
            <Alert.Heading>
              Disclaimer
            </Alert.Heading>
            <p className="pt-3 text-black-50 small font-italic">
              - The registration payments are non-refundable and non-transferable.
              <br />
              - By continuing with payment and registration, you agree to receive emails from us,
              both informational and promotional. You may opt out any time by contacting us at
              contact@nzpmc.com.
            </p>
          </Alert>

        </Col>
      </Row>
    </Row>
  );
}

export default PaymentReview;
