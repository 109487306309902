import { useContext } from 'react';
import {
  Col, Row,
} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContextProvider';
import SchoolCard from '../dashboard/SchoolCard';

function TeacherAccountPage() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  return (
    <>
      <Row>
        <Col>
          <SchoolCard teacher={auth} />
        </Col>
        <Col />
      </Row>
    </>
  );
}
export default TeacherAccountPage;
