export const STUDENT_ROLE = 'Student';
export const TEACHER_ROLE = 'Teacher';
export const ADMIN_ROLE = 'Admin';

export function getRole(auth) {
  return auth?.role;
}

export function isStudent(auth) {
  return getRole(auth) === STUDENT_ROLE;
}

export function isTeacher(auth) {
  return getRole(auth) === TEACHER_ROLE;
}

export function isAdmin(auth) {
  return getRole(auth) === ADMIN_ROLE;
}
