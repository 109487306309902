import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  Card, Col,
  Row,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import { getAllAnnouncements } from '../../services/announcement';
import { getEvents } from '../../services/events';
import { getFormattedNZTDateString } from '../../utils/AnnouncementUtils';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import { isStudent, isTeacher } from '../../utils/roleComputation';
import AnnouncementList from '../announcement/AnnouncementList';
import Loading from '../common/Loading';
import EventActions from '../dashboard/EventActions';
import './EventView.css';

function EventList() {
  const [events, setEvents] = useState();
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();

  useEffect(() => {
    withLoading(
      getEvents(auth),
      (res) => setEvents(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const [loading2, withLoading2] = useWithLoading();
  const [announcements, setAnnouncements] = useState();

  const { authRetrievedProfile } = useContext(AuthContext);

  useEffect(() => {
    withLoading2(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Loading loading={loading}>
        <PleaseReadFaq />
        <Row>
          <small className="fw-bold text-muted">
            Recent Announcements (
            <Link to="/announcements">View all</Link>
            )
          </small>
          <br />
        </Row>
        <Loading loading={loading2} message="Retrieving announcements">
          <AnnouncementList announcements={announcements?.slice(0, 4)} />
          <br />
        </Loading>
        <Row>
          <small className="fw-bold text-muted">All Events</small>
          <br />
        </Row>
        <Row>
          {events?.map(((singleEvent, index) => <EventCard event={singleEvent} index={index + 1} />))}
          <br />
        </Row>
      </Loading>
    </>
  );
}

function PleaseReadFaq() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  return (isStudent(auth) || isTeacher(auth)) && (
    <Row>
      <Col>
        <Alert variant="info">
          New here? Please read the FAQ section!
        </Alert>
      </Col>
    </Row>
  );
}

function EventCard({ event }) {
  const history = useHistory();
  const { authRetrievedProfile: auth } = useContext(AuthContext);

  const handleEventCardButtonClick = () => {
    history.push(`/dashboard/${event?.eventCode}`);
  };
  return (
    <Col lg={4} className="mb-3">
      <Card className="h-100 event-card" onClick={handleEventCardButtonClick}>
        <Card.Body>
          <Row>
            <div className="fw-bold lead">{event?.eventName}</div>
            <br />
            <small style={{ color: 'red' }}>
              Deadline:
              {' '}
              {getFormattedNZTDateString(event?.registrationDueDate)}
            </small>
            <small>
              Event Date:
              {' '}
              {getFormattedNZTDateString(event?.eventDate)}
            </small>
          </Row>
        </Card.Body>
        <Card.Footer>
          <EventActions event={event} readOnly />
        </Card.Footer>
      </Card>
    </Col>
  );
}
export default EventList;
