import { Link, useLocation } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';

const pageMetadata = {
  titleLH: 'Thank',
  titleRH: 'You',
  body: 'Your registration/payment is now complete. Confirmation will be sent to your email shortly.',
};

export default function ThankYou() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return (
    <>
      <p className="mt-3">
        If you have any trouble receiving the email confirmation, please contact contact@nzpmc.com with your
        email
        address as a reference.
      </p>
      <Link to="/">
        <Button>Back to Home</Button>
      </Link>
    </>
  );
}
