import axios from 'axios';

export const onCreateStudentAsync = (details) => async () => await axios.post('/students', details);

export const onEditStudentAsync = (auth, session) => async () => await axios.put(`/students/changeNameLoudly?email=${auth.email}&code=${auth.code}`, session);

export const calculateAndUpdateYearLevels = (auth) => axios.put(`/admin/yearLevels?email=${auth.email}&code=${auth.code}`)
  .then((res) => {
    alert(res.status === 200 ? 'Successful!' : 'Unexpected status code, perhaps contact the developers.');
  })
  .catch((error) => {
    if (error.response.status === 503) {
      alert('Server took too long to respond, but probably updated successfully.');
    } else {
      alert(error.response.data.error);
    }
  });
