import { Card } from 'react-bootstrap';

function BoxShadowCard({ children }) {
  return (
    <Card style={{ boxShadow: '10px 10px', border: '1px solid black' }} className="p-4">
      {children}
    </Card>
  );
}
export default BoxShadowCard;
