import { Spinner } from 'react-bootstrap';

export default function ({ children, loading, message = 'Loading...' }) {
  return (
    <>
      {loading && (
      <>
        <Spinner />
        {message}
      </>
      )}
      {!loading && children}
    </>
  );
}
