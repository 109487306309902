import { EditorContent, useEditor } from '@tiptap/react';
import React from 'react';
import { RICH_TEXT_EXTENSIONS } from './config';

function RichTextDisplay({ content }) {
  const editor = useEditor({ extensions: RICH_TEXT_EXTENSIONS });
  if (!editor) return null;

  editor.setEditable(false);
  editor.commands.setContent(content);

  return (
    <EditorContent editor={editor} />
  );
}

export default RichTextDisplay;
