import { useContext } from 'react';
import { SessionContext } from '../../../context/SessionContextProvider';
import { PrettyFormGroup } from './FormComponents';

/**
 *
 * @param data It can be:
 *              List (attribute = display label) or
 *              Object (attribute != displayed label)
 *             For Object, ensure that <key, value> = <displayed label, attribute>
 * @returns {JSX.Element}
 * @constructor
 */
export default function SingleSelectInputFromList({
  label, field, data, required = false,
}) {
  const { session, handleSessionChange } = useContext(SessionContext);

  function GenerateOptions() {
    if (Array.isArray(data)) return <>{data.map((e) => <option key={e} value={e}>{e}</option>)}</>;
    return <>{Object.keys(data).map((e) => <option key={data[e]} value={data[e]}>{e}</option>)}</>;
  }

  return (
    <PrettyFormGroup className="my-3" label={label} required={required}>
      <select
        onChange={handleSessionChange(field)}
        className="form-select"
        aria-label="select example"
        required={required}
        value={session[field]}
      >
        <option value="">Select...</option>
        <GenerateOptions />
      </select>
    </PrettyFormGroup>
  );
}
