import { ListGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { getNumberOfComments } from '../../pages/AnnouncementPage';
import { getDateStringFromAnnouncement, removeHtmlTags } from '../../utils/AnnouncementUtils';
import ListItemWithBadge from './listitem/ListItemWithBadge';

function attachment(attachmentLength) {
  return attachmentLength ? `${attachmentLength} attachments` : '';
}

export default function AnnouncementList({ announcements }) {
  const history = useHistory();

  return (
    <ListGroup>
      {announcements ? announcements?.map((announcement) => (
        <ListGroup.Item
          key={announcement.id}
          onClick={() => history.push(`/announcements/${announcement.id}`)}
          action
          className="d-flex justify-content-between align-items-start"
        >
          <ListItemWithBadge
            title={announcement.title}
            body={removeHtmlTags(announcement.body)}
            dateString={getDateStringFromAnnouncement(announcement)}
            badgeLabel={`${(announcement?.comments && getNumberOfComments(announcement.comments)) || 0} comments`}
            attachment={attachment(announcement.attachmentURLs?.length)}
          />
        </ListGroup.Item>
      )) : <div>No announcements</div>}
    </ListGroup>
  );
}
