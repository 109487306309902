import { Button, Card } from 'react-bootstrap';
import { UserIcon } from '../../../icons/icons';
import BoxShadowCard from '../../common/layout/BoxShadowCard';

function StudentCard({ student }) {
  const optionalMiddleName = student.middleName ? ` ${student.middleName}` : '';
  const name = `${student.firstName}${optionalMiddleName} ${student.lastName}`;

  return (
    <BoxShadowCard>
      <span className="text-center">
        <UserIcon />
      </span>
      <span className="pt-2 text-center lead fw-bold">
        <span className="fw-normal small">Name: </span>
        {name}
      </span>
      <span className="text-center lead fw-bold">
        <span className="fw-normal small">Email: </span>
        {student.email}
      </span>
      <span className="text-center lead fw-bold">
        <span className="fw-normal small">Year level: </span>
        {student.yearLevel}
      </span>
    </BoxShadowCard>
  );
}
export default StudentCard;
