import { Button, Col } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../context/AuthContextProvider';

export default function () {
  const { onLogOut, authRetrievedProfile } = useContext(AuthContext);
  const history = useHistory();

  return (
    <Col xs={12} className="mb-3">
      <span className="small">
        Hello,
        {' '}
        {authRetrievedProfile.name}
      </span>
      <Link to="/account">
        <Button variant="primary" className="py-1 mx-2">
          Account
        </Button>
      </Link>
      <Link to="/">
        <Button
          onClick={
          () => {
            // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
            history.go(0);
            onLogOut();
          }
}
          variant="warning"
          className="py-1 mx-2"
        >
          Sign Out
        </Button>
      </Link>
    </Col>
  );
}
