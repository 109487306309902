import { Badge } from 'react-bootstrap';

export default function ListItemWithBadge({
  title, body, dateString = '', badgeLabel, badgeColour = 'primary', attachment, attachmentColour = 'success',
}) {
  const bodyStyle = {
    whiteSpace: 'nowrap',
    maxWidth: '',
    wordWrap: '',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  };

  return (
    <>
      <div className="ms-2 me-auto text-truncate" style={{ width: '90%' }}>
        <div className="fw-bold text-truncate">
          {title}
          {' | '}
          <span className="fw-normal text-muted">{dateString}</span>
        </div>
        <div style={bodyStyle}>
          {body}
        </div>
      </div>
      <div style={{
        display: 'flex', flexDirection: 'column', gap: '5px', width: '100px',
      }}
      >
        <Badge bg={badgeColour}>
          {badgeLabel}
        </Badge>
        <Badge bg={attachmentColour}>
          {attachment}
        </Badge>
      </div>
    </>
  );
}
