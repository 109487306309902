import { forwardRef, useContext } from 'react';
import Form from 'react-bootstrap/Form';
import { SessionContext } from '../../../context/SessionContextProvider';
// eslint-disable-next-line import/no-cycle
import { PrettyFormGroup } from './FormComponents';

// IMPORTANT: required is by default true
const TextBoxInput = forwardRef((props, ref) => {
  const {
    label, field, required = true, enforceNumeric = false, onEnter = () => { },
  } = props;
  const { handleSessionChange } = useContext(SessionContext);

  const handleKeyUp = (e) => {
    e.preventDefault();
    if (e.key === 'Enter') {
      onEnter(e);
    }
  };

  return (
    <PrettyFormGroup label={label}>
      <Form.Control
        onChange={handleSessionChange(field)}
        type={enforceNumeric ? 'number' : 'text'}
        required={required}
        onKeyUp={handleKeyUp}
        ref={ref}
        name={field}
      />
    </PrettyFormGroup>
  );
});

export default TextBoxInput;
