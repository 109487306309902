import {
  useContext, useEffect, useState,
} from 'react';
import {
  Accordion, Alert, Breadcrumb,
} from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Loading from '../components/common/Loading';
import MulticolumnLayout from '../components/common/layout/MulticolumnLayout';
import { NavigatableTabs } from '../components/dashboard/DashboardComponents';
import NZTDatePicker from '../components/forms/common/NZTDatePicker';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import SingleSelectInputFromList from '../components/forms/common/SingleSelectInputFromList';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import RichTextInput from '../components/forms/common/richtext/RichTextInput';
import { ANNOUNCEMENT_NOTIFICATION_SCOPE } from '../configs';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { createAnnouncement } from '../services/announcement';
import { getAllAvailableEventsNames } from '../services/events';
import { isValidDate } from '../utils/DateTimeUtils';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import { ADMIN_ROLE } from '../utils/roleComputation';
import { AnnouncementContent } from './AnnouncementPage';

const pageMetadata = {
  titleLH: 'Admin tool:',
  titleRH: 'Create Announcement',
  body: 'Create announcements! Creating an announcement also emails everyone - be careful.',
};

function createAnnouncementPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const history = useHistory();

  useEffect(() => {
    // We do a security check to ensure that only an admin account is on this page.
    if (!authRetrievedProfile || authRetrievedProfile.role !== ADMIN_ROLE) {
      history.push('/announcements');
    }
  }, []);

  const formulateAnnouncement = () => {
    const announcement = { ...session };
    announcement.attachmentURLs = [session.attachment1, session.attachment2].filter(Boolean);
    announcement.scheduledDate = new Date(session.scheduledDate);
    return announcement;
  };

  const tabs = {
    Editor: <Editor formulateAnnouncement={formulateAnnouncement} />,
    Preview: <Preview formulateAnnouncement={formulateAnnouncement} />,
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push('/announcements')}>Announcements</Breadcrumb.Item>
        <Breadcrumb.Item active>Create Announcement Page</Breadcrumb.Item>
      </Breadcrumb>
      <NavigatableTabs tabs={tabs} />
    </>
  );
}

function Editor({ formulateAnnouncement }) {
  const [formLoading, withformLoading] = useWithLoading();
  const [eventListLoading, withEventListLoading] = useWithLoading();
  const { authRetrievedProfile } = useContext(AuthContext);
  const [eventList, setEventList] = useState([]);
  const history = useHistory();

  useEffect(() => {
    withEventListLoading(
      getAllAvailableEventsNames,
      (res) => setEventList(res.data),
      () => setEventList([]),
    );
  }, []);

  const addAnnouncement = async () => {
    // Create payload and attempt to post.
    const { email, code } = authRetrievedProfile;
    const announcement = formulateAnnouncement();
    withformLoading(
      async () => createAnnouncement({ email, code, announcement }),
      () => {
        alert('Announcement created!');
        history.push('/announcements');
      },
      ALERT_ERROR_MESSAGE,
    );
  };

  return (
    <PrettyForm
      submitText="Create Announcement"
      onSubmit={addAnnouncement}
      cancelText="Back to announcements"
      onCancel={() => history.push('/announcements')}
      loading={formLoading}
    >
      <MulticolumnLayout>
        <SingleSelectInputFromList
          key="email"
          label="📧 Emails to"
          field="notificationScope"
          data={ANNOUNCEMENT_NOTIFICATION_SCOPE}
        />
        <NZTDatePicker key="date" label="⏰ Schedule" field="scheduledDate" options={{ hourOnly: true }} />
        <Loading key="events" loading={eventListLoading}>
          <SingleSelectInputFromList label="🎪 Event" field="forEventCode" data={eventList} />
        </Loading>
      </MulticolumnLayout>
      <TextBoxInput label="🙂 Title" field="title" required />
      <RichTextInput label="😀 Body (Make a draft on Gmail. Images don't transfer over, but you can add it.)" field="body" required />
      <h3>In addition, you can add pdfs/images/google docs as an attachment</h3>
      <Instructions />
      <TextBoxInput label="📗 PDF/Image 1 (Optional)" field="attachment1" required={false} />
      <TextBoxInput label="📕 PDF/Image 2 (Optional)" field="attachment2" required={false} />
    </PrettyForm>
  );
}

function Instructions() {
  return (
    <Accordion defaultActiveKey="-1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          <b>📣 Please note that attachments should be embedded Google Drive links.</b>
        </Accordion.Header>
        <Accordion.Body>
          <li>Upload your attachment to Google Drive.</li>
          <li>In sharing settings, ensure that the file is public.</li>
          <li>Open the file, click the triple dots (top right) and open in a new window.</li>
          <li>
            Click the triple dots again and select "embed items".
            <i>
              You should see:
              &lt;iframe src="LINK_HERE" width="X" height="Y" allow="autoplay"&gt;&lt;/iframe&gt;.
              {' '}
            </i>
          </li>
          <li>Copy the link within the quotes "LINK_HERE" and paste the link in the attachments field.</li>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function Preview({ formulateAnnouncement }) {
  // Handle send now case
  const previewAnnouncement = { ...formulateAnnouncement(), creationDate: new Date() };
  if (!isValidDate(previewAnnouncement.scheduledDate)) {
    previewAnnouncement.scheduledDate = null;
  }

  return (
    <>
      <Alert variant="warning" style={{ paddingBottom: '4px' }}>
        <p>
          <strong>Notification Scope: </strong>
          {previewAnnouncement.notificationScope}
        </p>
        <p>
          <strong>Event Code: </strong>
          {previewAnnouncement.forEventCode}
        </p>
      </Alert>
      <AnnouncementContent announcement={previewAnnouncement} isPreview />
    </>
  );
}

export default createAnnouncementPage;
