import { Accordion } from 'react-bootstrap';

export function ExpandWrapper({ children, openByDefault }) {
  return (
    <Accordion defaultActiveKey={openByDefault}>
      {children}
    </Accordion>
  );
}

export function Expand({ header, children }) {
  return (
    <Accordion.Item eventKey={header}>
      <Accordion.Header>
        {header}
      </Accordion.Header>
      <Accordion.Body>
        {children}
      </Accordion.Body>
    </Accordion.Item>
  );
}
