import { EditorContent, useEditor } from '@tiptap/react';
import { useContext, useEffect } from 'react';
import { SessionContext } from '../../../../context/SessionContextProvider';
import { PrettyFormGroup } from '../FormComponents';
import styles from './RichTextInput.module.css';
import RichTextMenu from './RichTextMenu';
import { RICH_TEXT_EXTENSIONS, sampleRichTextContent } from './config';

function RichTextInput({ label, field }) {
  const { handleSessionChange } = useContext(SessionContext);
  const editor = useEditor({
    content: sampleRichTextContent,
    extensions: RICH_TEXT_EXTENSIONS,
  });

  useEffect(() => {
    // Sync the initial content with the session
    // I don't want to do this inside SessionContextProvider itself since it's shared
    handleSessionChange(field, false, true)(sampleRichTextContent);
  }, [editor]);

  useEffect(() => {
    // Event listener for changes in the editor
    const handleEditorUpdate = ({ editor }) => handleSessionChange(field, false, true)(editor.getHTML());
    editor?.on('update', handleEditorUpdate);
    return () => editor?.off('update', handleEditorUpdate); // Cleanup
  }, [editor, handleSessionChange]);

  if (!editor) return null;

  return (
    <PrettyFormGroup label={label}>
      <div className={styles.editor}>
        <RichTextMenu editor={editor} />
        <EditorContent editor={editor} contentEditable="false" />
      </div>
    </PrettyFormGroup>
  );
}

export default RichTextInput;
