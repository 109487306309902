import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';

export default function DisplayForRole({ forRole, children }) {
  const { authRetrievedProfile } = useContext(AuthContext);
  const roleMatched = authRetrievedProfile?.role === forRole;

  return (
    <>
      {roleMatched && children}
    </>
  );
}
