import { useContext, useState } from 'react';
import { SessionContext } from '../../../context/SessionContextProvider';
import { getNowNZT, toDateTimeInputValueString, toNZTString } from '../../../utils/DateTimeUtils';
import Tooltip from '../../common/Tooltip';
import { PrettyFormGroup } from './FormComponents';

export default function NZTDatePicker({
  label, field, options = {}, required = false,
}) {
  const { handleSessionChange } = useContext(SessionContext);
  const [date, setDate] = useState(); // just cuz, I want nzt to be displayed as it changes
  const { hourOnly } = options;
  const onChange = (event) => {
    const selectedDate = new Date(event?.target?.value);
    if (hourOnly) selectedDate.setMinutes(0);
    handleSessionChange(field, false, true)(selectedDate);
    setDate(selectedDate);
  };

  return (
    <PrettyFormGroup label={label} required={required}>
      <p>
        <input
          className="form-select"
          style={{ backgroundImage: 'none', paddingRight: '12px' }} // form-select is not intended for this
          type="datetime-local"
          id="scheduled"
          onChange={onChange}
          value={toDateTimeInputValueString(date)}
        />
        <small>{toNZTString(date) === 'Invalid Date' ? `Now: ${getNowNZT()}` : toNZTString(date)}</small>
        { hourOnly && <Tooltip tip="Valid values: on the hour or right now" />}
      </p>
    </PrettyFormGroup>
  );
}
