import { useContext } from 'react';
import {
  Button, Col, Row,
} from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContextProvider';
import { SessionContext } from '../../context/SessionContextProvider';
import useWithLoading from '../../hooks/useWithLoading';
import { onEditStudentAsync } from '../../services/student';
import { ALERT_ERROR_MESSAGE } from '../../utils/ResponseUtils';
import Loading from '../common/Loading';
import BoxShadowCard from '../common/layout/BoxShadowCard';
import SchoolCard from '../dashboard/SchoolCard';
import TextBoxInput from '../forms/common/TextBoxInput';
import StudentCard from './cards/StudentCard';

function StudentAccountPage() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);

  return (
    <>
      <Row>
        <Col xs={6}>
          <StudentCard student={auth} />
          <br />
          <SchoolCard teacher={auth.teacher} />
        </Col>
        <Col xs={6}>
          <BoxShadowCard>
            <StudentEditDetailsCard />
          </BoxShadowCard>
        </Col>
      </Row>
    </>
  );
}

function StudentEditDetailsCard() {
  const { session } = useContext(SessionContext);
  const { authRetrievedProfile, setAuthRetrievedProfile } = useContext(AuthContext);
  const [loading, withLoading] = useWithLoading();

  const onSubmit = () => withLoading(
    onEditStudentAsync(authRetrievedProfile, session),
    (res) => {
      setAuthRetrievedProfile({
        ...authRetrievedProfile,
        firstName: res.data.firstName,
        middleName: res.data.middleName,
        lastName: res.data.lastName,
      });
      // apparently causes full page reload, which is nasty, but I couldn't find a better alternative with react-router-dom-v5
      // eslint-disable-next-line no-restricted-globals
      history.go(0);
    },
    ALERT_ERROR_MESSAGE,
  );

  return (
    <Loading loading={loading}>
      <b>Do you need to edit your name?</b>
      <br />
      <small>Be sure to get it right before you register for any event.</small>
      <TextBoxInput label="Your First Name" field="firstName" />
      <TextBoxInput label="Your Middle Name (Optional)" field="middleName" required={false} />
      <TextBoxInput label="Your Last Name" field="lastName" />
      <Button onClick={onSubmit}>Change my name</Button>
    </Loading>
  );
}

export default StudentAccountPage;
