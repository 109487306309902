import { useContext } from 'react';
import { TextBoxInputWithButton } from '../common/FormComponents';
import { SessionContext } from '../../../context/SessionContextProvider';
import { authenticateNewUser, sendCodeNewUser } from '../../../services/authroutes';
import useWithLoading from '../../../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE, ALERT_RES_DATA } from '../../../utils/ResponseUtils';

/**
 * This form is a validation for user's email during platform registration process
 * Note: This is not for signing up to an event.
 * @param forStudent
 * @returns {JSX.Element}
 * @constructor
 */
export default function SignUpInput({ forStudent }) {
  const { session } = useContext(SessionContext);
  const [loading, withLoading] = useWithLoading();

  return (
    <>
      <TextBoxInputWithButton
        label={`Your Email ${forStudent ? '(Should not be your school email)' : ''}`}
        field="email"
        handleClick={() => withLoading(sendCodeNewUser(session), ALERT_RES_DATA, ALERT_ERROR_MESSAGE)}
        loading={loading}
      >
        Send Code
      </TextBoxInputWithButton>
      <TextBoxInputWithButton
        label="Email Validation Code"
        field="code"
        handleClick={() => withLoading(authenticateNewUser(session), ALERT_RES_DATA, ALERT_ERROR_MESSAGE)}
        loading={loading}
      >
        Validate Code
      </TextBoxInputWithButton>
    </>
  );
}
