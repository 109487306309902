import { Alert } from 'react-bootstrap';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import {
  AutoCompleteTextBoxInput,
  Section,
} from '../components/forms/common/FormComponents';
import SignUpInput from '../components/forms/signup/SignUpInput';
import { CITY, ISLANDS, TEACHER_SUBJECT } from '../configs';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import { PageContext } from '../context/PageContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { onCreateTeacherAsync } from '../services/teacher';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import Loading from '../components/common/Loading';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import SingleSelectInputFromList from '../components/forms/common/SingleSelectInputFromList';
import CheckBoxInput from '../components/forms/common/CheckBoxInput';

const pageMetadata = {
  titleLH: 'Platform Registration for',
  titleRH: 'Teacher',
  body: 'Once you complete the registration, your students will be able to register under your email.',
};

export default function TeacherSignupPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const { session } = useContext(SessionContext);
  const history = useHistory();
  const [loading, withLoading] = useWithLoading();

  const [schoolNames, setSchoolNames] = useState(['Loading...']);

  useEffect(() => {
    withLoading(
      async () => await axios.get('/schoolMetadata'),
      (res) => setSchoolNames(res.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  const onSubmit = () => withLoading(
    onCreateTeacherAsync(session),
    () => history.push('/ThankYou'),
    ALERT_ERROR_MESSAGE,
  );

  return (
    <PrettyForm
      submitText="Sign me up 🙈"
      cancelText="Go back 😔"
      onSubmit={onSubmit}
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <Section title="1. Personal Details">
        <TextBoxInput label="Enter your full name" field="name" />
        <SingleSelectInputFromList label="I am a..." field="teacherCategory" data={TEACHER_SUBJECT} />
        <TextBoxInput label="Phone Number" field="phoneNumber" />
        <SignUpInput forStudent={false} />
      </Section>
      <hr />
      <Section title="2. School Details">
        <Alert variant="warning">
          Please provide the full address of your school. We will use this address to send certificates.
        </Alert>
        <Loading loading={loading}>
          <AutoCompleteTextBoxInput label="School Name" field="schoolName" data={schoolNames} disclaimerLabel="Some schools may not appear on the autocomplete." />
        </Loading>
        <CheckBoxInput label="Homeschooled (school name will be ignored)" field="homeSchooled" />
        <TextBoxInput label="School Address" field="schoolAddress" />
        <SingleSelectInputFromList label="My school is in..." field="island" data={ISLANDS} />
        <SingleSelectInputFromList label="My school is in the city of..." field="city" data={CITY} />
      </Section>
    </PrettyForm>
  );
}
