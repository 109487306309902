import { useContext, useEffect, useState } from 'react';
import { Breadcrumb, Table } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Loading from '../components/common/Loading';
import { Expand, ExpandWrapper } from '../components/common/layout/Expand';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import useWithLoading from '../hooks/useWithLoading';
import { getStudentsFromEvents } from '../services/events';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';

const pageMetadata = {
  titleLH: 'Registration Summary',
  titleRH: '',
  body: '',
};

function RegisteredStudentsPage() {
  const { eventCode } = useParams();
  const { authRetrievedProfile } = useContext(AuthContext);
  const [summaryMap, setSummaryMap] = useState(null);
  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    pageMetadata.titleRH = `(${eventCode})`;
    pageMetadata.body = `This page shows students registered under ${authRetrievedProfile.email}`;
    setPageMetadata(pageMetadata);
  }, []);

  useEffect(() => {
    withLoading(
      getStudentsFromEvents(authRetrievedProfile, eventCode),
      (res) => {
        setSummaryMap(res.data);
      },
      ALERT_ERROR_MESSAGE,
    );
  }, [authRetrievedProfile, eventCode]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item onClick={() => history.push(`/dashboard/${eventCode}`)}>{eventCode}</Breadcrumb.Item>
        <Breadcrumb.Item active>Registration Summary</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading}>
        <TableView
          heading="✅ Registered"
          description="Students that registered (and if necessary, paid) for this event"
          studentsFromEvents={summaryMap?.Registered}
        />
        <TableView
          heading="⏸️ Not yet registered"
          studentsFromEvents={summaryMap?.Invited}
          description="Students who were invited for this event but have not registered yet (please encourage them to register!)"
          emptyMessage={!summaryMap?.Invited && 'This event is open to everyone'}
        />
        <TableView
          heading="❌ Declined"
          description="Students who were invited but have declined to register for this event"
          studentsFromEvents={summaryMap?.Declined}
        />
      </Loading>
    </>

  );
}

function TableView({
  heading, description, studentsFromEvents, emptyMessage = 'No students',
}) {
  return (
    <div className="my-4">
      <h3>{heading}</h3>
      <ExpandWrapper openByDefault={description}>
        <Expand header={description}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Name</th>
                <th>Year Level</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              {studentsFromEvents && studentsFromEvents.length > 0 ? (
                studentsFromEvents.map((student, index) => (
                  <tr key={student?.id ? student.id.value : index}>
                    <td>{student?.personSnapshot?.studentName}</td>
                    <td>{student?.personSnapshot?.yearLevel}</td>
                    <td>{student?.personSnapshot?.studentEmail}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">{emptyMessage}</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Expand>
      </ExpandWrapper>
    </div>
  );
}

export default RegisteredStudentsPage;
