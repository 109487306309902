import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { registerForEvent } from '../../../../services/events';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { SessionContext } from '../../../../context/SessionContextProvider';
import useWithLoading from '../../../../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import PaymentReview from '../../payment/PaymentReview';
import { PrettyForm } from '../../common/PrettyForm';
import CheckBoxInput from '../../common/CheckBoxInput';

export default function SimpleOnlineWorkshopForm({ eventCode, time }) {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  const onSubmit = () => withLoading(
    registerForEvent(authRetrievedProfile, eventCode, session.paymentMethod, session),
    (res) => window.location.replace(res?.data),
    ALERT_ERROR_MESSAGE,
  );

  const confirmDateText = (
    <>
      Can you confirm that you can attend this workshop through zoom at
      {' '}
      <b>{time}</b>
      ?
      (Zoom link will be provided in the further announcement)
    </>
  );

  return (
    <PrettyForm
      submitText="✅ Complete Registration"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <PaymentReview eventCode={eventCode} />
      <hr />
      <CheckBoxInput label={confirmDateText} field="confirmDate" required />
    </PrettyForm>
  );
}
