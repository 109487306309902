import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';
import LoginInput from '../components/forms/signup/LoginInput';

const pageMetadata = {
  titleLH: 'Login to',
  titleRH: 'NZPMC Portal',
  body: "Here, you can register for workshops and competitions. Use your registered email to sign in. Passwords are so 90's! We will send you unique code to verify ownership of your email.",
};

export default function () {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return <LoginInput />;
}
