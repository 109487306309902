import { Col, Row } from 'react-bootstrap';

// you must supply >1 children, each with a unique key
function MulticolumnLayout({ children }) {
  return (
    <Row>
      {children?.map((item) => <Col key={item.key} xs={12} lg={4}>{item}</Col>)}
    </Row>
  );
}
export default MulticolumnLayout;
