export const toNZTString = (date, options) => {
  const fullOptions = {
    timeZone: 'Pacific/Auckland',
    timeZoneName: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  };
  return new Date(date).toLocaleString('en-NZ', fullOptions);
};

export const getNowNZT = () => toNZTString(new Date());

export const toDateTimeInputValueString = (date) => {
  if (!isValidDate(date)) return '';
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const isValidDate = (date) => date && date instanceof Date && !Number.isNaN(date.valueOf());
