import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { registerForEvent } from '../../../../services/events';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { SessionContext } from '../../../../context/SessionContextProvider';
import useWithLoading from '../../../../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import PaymentReview from '../../payment/PaymentReview';
import {
  EVENT_NAMES,
  FUTURE_CAREER_PATH,
  HOW_DID_YOU_HEAR,
  PARTICIPATION_REASONS,
  PAYMENT_METHODS,
} from '../../../../configs';
import { PrettyForm } from '../../common/PrettyForm';
import TextBoxInput from '../../common/TextBoxInput';
import SingleSelectInputFromList from '../../common/SingleSelectInputFromList';
import CheckBoxInput from '../../common/CheckBoxInput';
import { MultiCheckBoxInputFromData } from '../../common/MultiCheckBoxInputFromData';

export default function NZPMC24R1({ eventCode }) {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  const onSubmit = () => withLoading(
    registerForEvent(authRetrievedProfile, eventCode, session.paymentMethod, session),
    (res) => window.location.replace(res?.data),
    ALERT_ERROR_MESSAGE,
  );

  return (
    <PrettyForm
      submitText="Proceed to pay"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <PaymentReview eventCode={eventCode} />
      <SingleSelectInputFromList label="Choose method of payment" field="paymentMethod" data={PAYMENT_METHODS} />
      <hr />
      <SingleSelectInputFromList label="🤔 Reasons for participation" field="ParticipationReason" data={PARTICIPATION_REASONS} />
      <MultiCheckBoxInputFromData label="🏆 Have you participated in the following events? (Multi-select)" json={EVENT_NAMES} />
      <SingleSelectInputFromList label="📢 How did you hear about this event?" data={HOW_DID_YOU_HEAR} />
      <SingleSelectInputFromList label="🛣️ Which path describes your desired future career path the best?" data={FUTURE_CAREER_PATH} />
      <TextBoxInput label="🙈 Is there anything else you would like to let us know?" field="fyi" />
      <CheckBoxInput label="I am willing to attend an in-person final if I advance past round 1." field="willingFinal" />
    </PrettyForm>
  );
}
