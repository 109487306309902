import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { registerForEvent } from '../../../../services/events';
import { AuthContext } from '../../../../context/AuthContextProvider';
import { SessionContext } from '../../../../context/SessionContextProvider';
import useWithLoading from '../../../../hooks/useWithLoading';
import { ALERT_ERROR_MESSAGE } from '../../../../utils/ResponseUtils';
import PaymentReview from '../../payment/PaymentReview';
import {
  DIETARY_REQUIREMENTS,
  EMERGENCY_CONTACT_RELATIONSHIP,
  PAYMENT_METHODS, WORK_FOR_NZPMC,
} from '../../../../configs';
import { PrettyForm } from '../../common/PrettyForm';
import TextBoxInput from '../../common/TextBoxInput';
import SingleSelectInputFromList from '../../common/SingleSelectInputFromList';
import CheckBoxInput from '../../common/CheckBoxInput';

export default function NZPMC24R2({ eventCode }) {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);

  const [loading, withLoading] = useWithLoading();
  const history = useHistory();

  const onSubmit = () => withLoading(
    registerForEvent(authRetrievedProfile, eventCode, session.paymentMethod, session),
    (res) => window.location.replace(res?.data),
    ALERT_ERROR_MESSAGE,
  );

  return (
    <PrettyForm
      submitText="Proceed to pay"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <PaymentReview eventCode={eventCode} />
      <hr />
      <h3>Payment Type</h3>
      <SingleSelectInputFromList label="💳 Choose method of payment" field="paymentMethod" data={PAYMENT_METHODS} />
      <hr />
      <h3>Emergency Contact</h3>
      <SingleSelectInputFromList label="🔗 Relationship" field="emergencyContactRelationship" data={EMERGENCY_CONTACT_RELATIONSHIP} />
      <TextBoxInput label="✏️ Contact's full name" field="emergencyContactName" />
      <TextBoxInput label="📞 Contact's Number" field="emergencyContactNumber" enforceNumeric />
      <hr />
      <h3>Consent</h3>
      <CheckBoxInput label="📷 Do you consent to having your photo taken and potentially published? (Leave blank if no)" field="giveMediaConsent" />
      <CheckBoxInput
        label="🧑 If you are under 14 years old at the date of the event,
        you agree to bring a supervisor (over 18 years old) with you on the day for the entire event."
        field="agreeToSupervisor"
        required={false}
      />
      <hr />
      <h3>Special Dietary Requirements (Optional)</h3>
      <SingleSelectInputFromList label="🍎 Special dietary requirements" field="dietaryRequirements" data={DIETARY_REQUIREMENTS} required={false} />
      <TextBoxInput label="🍎 If you selected 'Other', please specify:" field="dietaryRequirements" required={false} />
      <hr />
      <h3>Additional Details (Optional)</h3>
      <TextBoxInput label="⚙️ What sort of workshops would you be interested to see at NZPMC Round 2?" field="workshopPreferences" required={false} />
      <TextBoxInput
        label="❤️ Anything else you would like to let us know? If you need a special arrangement with exam conditions
        (i.e., font size, light setting, access to the venue) you must let us know to organise."
        field="fyi"
        required={false}
      />
    </PrettyForm>
  );
}
