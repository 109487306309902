import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContextProvider';
import { Expand, ExpandWrapper } from '../common/layout/Expand';

function GenericAccordion({ header, panels }) {
  const headerComponent = (typeof header === 'string') ? <h3>{header}</h3> : header;

  return (
    <>
      {headerComponent}
      <ExpandWrapper>
        {panels?.map((panel) => (<Expand header={panel.header}>{panel.content}</Expand>))}
      </ExpandWrapper>
    </>
  );
}

function NewHereAccordion({ panels }) {
  return <GenericAccordion header={<small className="fw-bold text-muted">New Here?</small>} panels={panels} />;
}

export function StudentNewHereAccordion() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const panels = [
    {
      header: "It's my first time here!",
      content: (
        <p>
          Welcome! Before
          {' '}
          <b>each registration due date</b>
          , please check your details are correct over in your account page: name (
          {auth.name}
          ),
          year level (
          {auth?.yearLevel}
          ), school name, and school address.
          If you find it to be incorrect, please contact us at contact@nzpmc.com. Please let us know at least
          {' '}
          <b>5 business days</b>
          {' '}
          before the registration is due.
        </p>
      ),
    },
    {
      header: "Am I currently registered for this year's NZPMC?",
      content: (
        <p>
          You for sure have an account on this website! But - have you done the following?
          <ol>
            <li>
              You need to go to the
              {' '}
              <b>Events</b>
              {' '}
              tab
            </li>
            <li>Click on the relevant competition/event</li>
            <li>And register & pay for the event.</li>
          </ol>
          If you just started using this platform, most likely you haven't registered for the
          actual competition yet.
        </p>
      ),
    },
  ];

  return <NewHereAccordion panels={panels} />;
}

export function TeacherNewHereAccordion() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const panels = [
    {
      header: "It's my first time here!",
      content: (
        <p>
          Welcome! You can click
          {' '}
          <b>Events</b>
          {' '}
          to go see all the
          available NZPMC events.
          The logistics are very simple for you! You just need to let students know of the email address you signed up with (
          {auth?.email}
          ) and they should be able to 1) create their account 2) register for an event.
        </p>
      ),
    },
    {
      header: 'I made a typo in the school name / address',
      content: (
        <p>
          We take your school name (case-sensitive) and school address written at the time of
          {' '}
          <b>
            registration due date
          </b>
          {' '}
          as the final details. We are not responsible for missed delivery due to incorrect address or typos
          on certificates. Please let us know if any change needs to be made at least 5 business days
          before the registration due date, so that we can change it for you!
        </p>
      ),
    },
  ];

  return <NewHereAccordion panels={panels} />;
}
