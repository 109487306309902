import EventList from '../../events/EventList';
import { NavigatableTabs } from '../DashboardComponents';
import FAQAccordion from '../FAQAccordion';
import { StudentNewHereAccordion } from '../NewHereAccordion';

export default function () {
  const tabs = {
    Events: <EventList />,
    FAQ: (
      <>
        <StudentNewHereAccordion />
        <br />
        <FAQAccordion />
      </>
    ),
  };

  return (<NavigatableTabs tabs={tabs} />);
}
