import axios from 'axios';

export const sendCode = (session) => async () => await axios.post(`/auth/refresh?email=${session.email}&isNewUser=${false}`);

export const sendCodeNewUser = (session) => async () => await axios.post(`/auth/refresh?email=${session.email}&isNewUser=${true}`);

export const authenticate = (session) => async () => await axios.post(`/auth/login?email=${session.email}&code=${session.code}&isNewUser=${false}`);

export const authenticateNewUser = (session) => async () => await axios.post(`/auth/login?email=${session.email}&code=${session.code}&isNewUser=${true}`);

// Protected by backend as only admins can do this
export const authenticateAsOtherUser = (auth, targetEmail) => async () => await axios.post(`/auth/admin/getValidationCode?email=${auth.email}&code=${auth.code}&targetEmail=${targetEmail}`);
