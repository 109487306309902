import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { AuthContext } from '../../../context/AuthContextProvider';
import { calculateAndUpdateYearLevels } from '../../../services/student';

import { PrettyCard } from '../DashboardComponents';

function YearLevelUpdater() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const [calculationLoading, setCalculationLoading] = useState(false);

  const handleCalculationClick = () => {
    setCalculationLoading(true);
    calculateAndUpdateYearLevels(authRetrievedProfile)
      .then(() => setCalculationLoading(false));
  };

  return (
    <PrettyCard title="Student yearLevel Updater">
      <p>
        Update tool (annual): Use when the new year comes around and the yearLevels of all the student documents
        need to be calculated and updated (or whenever you want, I guess - should be safe). Sends a PUT request
        that gets and updates every single student document based on the yearLevelOnCreation, creationDate year,
        and current year. Please wait patiently for the tool to run and try not to click button twice in short
        succession :)
      </p>
      <Button
        onClick={!calculationLoading ? () => handleCalculationClick() : null}
        disabled={calculationLoading}
      >
        {calculationLoading ? 'Updating...' : 'Update each student with correct yearLevels'}
      </Button>
    </PrettyCard>
  );
}

export default YearLevelUpdater;
