export const PAYMENT_METHODS = {
  'Polipay (Bank Account to Account)': 'POLIPAY',
  'Stripe (Credit/Debit card)': 'STRIPE',
};

export const EVENT_NAMES = {
  'NZPMC 2020': 'NZPMC20',
  'NZPMC 2021': 'NZPMC21',
  'NZPMC 2022 Round 1': 'NZPMC22R1',
  'NZPMC 2022 Round 2': 'NZPMC22R2',
  'NZPMC 2023 Round 1': 'NZPMC23R1',
  'NZPMC 2023 Round 2': 'NZPMC23R2',
  'NZPHO 2023 Round 1': 'NZPHO23R1',
  'NZPHO 2023 Round 2': 'NZPHO23R2',
  'Workshop 2023 - Intro': 'WORK23S1',
  'Workshop 2023 - WSP': 'WORK23S2',
};

export const YEAR_LEVELS = {
  'Year 7': '7',
  'Year 8': '8',
  'Year 9': '9',
  'Year 10': '10',
  'Year 11': '11',
  'Year 12': '12',
  'Year 13': '13',
};

export const ISLANDS = ['North Island', 'South Island'];

export const TEACHER_SUBJECT = ['Physics Teacher', 'Math Teacher', 'Other subject teacher', 'Home schooling parent', 'Others'];

export const ANNOUNCEMENT_NOTIFICATION_SCOPE = ['Everyone', 'InvitedForEvent', 'RegisteredForEvent'];

export const CITY = ['Auckland', 'Bay of Plenty', 'Canterbury', 'Gisborne', "Hawke's Bay", 'Marlborough', 'Nelson - Tasman', 'Northland', 'Otago', 'Southland', 'Taranaki', 'Waikato', 'Wellington', 'West Coast', 'Whanganui - Manawatu', 'Others'];

export const GENDERS = ['Male', 'Female', 'Other', 'Prefer not to say'];

export const ETHNICITY = ['European', 'Maori', 'Pacific Peoples', 'Asian', 'MELAA (Middle Eastern/Latin American/African)', 'Others', 'Prefer not to say'];

export const PARTICIPATION_REASONS = ['Parents', 'Friends', 'Teacher/School', 'To showcase my academic ability', 'To challenge myself', 'Prizes', 'It sounded fun', 'Others'];

export const HOW_DID_YOU_HEAR = ['Facebook', 'School', 'Teacher', 'Friend', 'Parent', 'The NZPMC website', 'Others'];

export const FUTURE_CAREER_PATH = ['STEM (Science/Technology/Engineering/Math)', 'Education', 'Marketing', 'Finance', 'Health Science', 'Hospitality', 'Information Technology', 'Business Administration', 'Architect', 'Sales', 'Human Resources', 'Manufacturing', 'Entertainment', 'Human Services', 'Transport and Logistics', 'Agriculture and Environment', 'Art and Design', 'Cyber Security', 'Data Scientist', 'Government', 'Accountant', 'Law', 'Security and Emergency', 'Administration', 'Researcher', 'Professor', 'Human Science (Philosophy, Social Science, ...)', 'Others'];

export const EMERGENCY_CONTACT_RELATIONSHIP = ['Father', 'Mother', 'Legal Guardian', 'Friend', 'Other'];

export const WORK_FOR_NZPMC = ['YES!', 'No :(', 'Still Considering'];

export const DIETARY_REQUIREMENTS = ['Vegetarian', 'Vegan', 'Gluten Free', 'Other'];
