import { Button, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { NavigatableTabs } from '../DashboardComponents';
import YearLevelUpdater from './YearLevelUpdater';
import DisplayForRole from '../../common/DisplayForRole';
import EventList from '../../events/EventList';
import { ADMIN_ROLE } from '../../../utils/roleComputation';

export default function AdminDashboard() {
  const tabs = {
    Events: <AdminLandingTab />,
    'Student yearLevel Updater': <YearLevelUpdater />,
  };
  return (<NavigatableTabs tabs={tabs} />);
}

function AdminLandingTab() {
  const history = useHistory();
  const moveToAdminPage = () => {
    history.push('/admin/createEvent');
  };

  return (
    <>
      <DisplayForRole forRole={ADMIN_ROLE}>
        <PillButton text="Add New Event (Workshop, Competition)" onClick={moveToAdminPage} />
      </DisplayForRole>
      <EventList />
    </>
  );
}

function PillButton({ text, onClick }) {
  return (
    <Button
      variant="outline-dark"
      className="mb-3 rounded-3 py-1"
      onClick={onClick}
    >
      {text}
    </Button>
  );
}
