import React, { useState } from 'react';
import CommentCard from './CommentCard';

export default function Comment({
  comment, addReply,
  isReply = false, parentName = '', parentContent = '', isUserLoggedIn = null,
}) {
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-start text-truncate border p-2 my-2"
        style={{ marginLeft: isReply ? '10px' : '0' }}
      >
        <CommentCard
          comment={comment}
          onReply={(replyContent) => addReply(comment, replyContent)}
          isReply={isReply}
          parentName={parentName}
          parentContent={parentContent}
          isUserLoggedIn={isUserLoggedIn}
        />
      </div>
      {comment.replies && comment.replies.length > 0 && (
        <div>
          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              addReply={addReply}
              isReply
              parentName={comment.name}
              parentContent={comment.content}
              isUserLoggedIn={isUserLoggedIn}
            />
          ))}
        </div>
      )}
    </>
  );
}
