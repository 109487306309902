import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';

const pageMetadata = {
  titleLH: 'Payment',
  titleRH: 'Cancelled',
  body: 'If you wish to register, return to the dashboard and restart the registration process.',
};

export default function () {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    setPageMetadata(pageMetadata);
  }, []);
  return (
    <Link to="/">
      <Button>Back to Home</Button>
    </Link>
  );
}
