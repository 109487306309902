import React, { useState } from 'react';
import {
  Badge, Button, ButtonGroup, InputGroup, Form,
} from 'react-bootstrap';

function truncateContent(content) {
  return content.slice(0, Math.floor(content.length / 2.5));
}

export default function CommentCard({
  comment, onReply, isReply, parentName, parentContent, isUserLoggedIn,
}) {
  const [showReplyBox, setReplyBoxVisibility] = useState(false);
  const [replyContent, setReplyContent] = useState();

  const roleColour = (role) => (role === 'Admin' ? 'danger' : (role === 'Teacher' ? 'warning' : 'success'));

  const handleReplyChange = (e) => setReplyContent(e.target.value);
  const submitReply = () => {
    onReply(replyContent);
    setReplyContent(null);
    setReplyBoxVisibility(false);
  };
  const cancelReply = () => {
    setReplyContent(null);
    setReplyBoxVisibility(false);
  };

  const openReplyBox = () => {
    setReplyBoxVisibility(true);
  };

  const arrow = '\u21B3';

  return (
    <div className="d-flex flex-column w-100">
      <div className="d-flex justify-content-between align-items-start w-100">
        <div className="ms-2 me-auto" style={{ width: '90%' }}>
          <div className="fw-bold">{comment.name}</div>
          {isReply && parentContent && (
            <i>
              <p className="fw-normal text-muted text-truncate w-20">
                {arrow}
                {' '}
                replying to
                {parentName}
                : '
                {truncateContent(parentContent)}
                ...'
              </p>
            </i>
          )}
          <div style={{ overflowWrap: 'break-word', whiteSpace: 'normal' }}>{comment.content}</div>
        </div>
        <div
          className="d-flex flex-column align-items-start"
        >
          <Badge bg={roleColour(comment.role)} style={{ marginBottom: '10px', width: '10vh' }}>
            {comment.role}
          </Badge>
          {!showReplyBox && isUserLoggedIn
            && (
            <Button
              variant="outline-secondary"
              size="sm"
              style={{ width: '100%' }}
              onClick={openReplyBox}
            >
              Reply
            </Button>
            )}
        </div>
      </div>
      {showReplyBox
          && (
          <ReplyBox
            replyContent={replyContent}
            handleReplyChange={handleReplyChange}
            submitReply={submitReply}
            cancelReply={cancelReply}
          />
          )}
    </div>
  );
}

function ReplyBox({
  replyContent, handleReplyChange, submitReply, cancelReply,
}) {
  return (
    <InputGroup className="mt-2">
      <Form.Control
        placeholder="Reply to this comment..."
        value={replyContent}
        onChange={handleReplyChange}
        style={{ marginLeft: '5px', marginRight: '10px' }}
      />
      <ButtonGroup>
        <Button variant="outline-secondary" size="sm" onClick={submitReply}>
          Reply
        </Button>
        <Button variant="outline-danger" size="sm" onClick={cancelReply}>
          Cancel
        </Button>
      </ButtonGroup>
    </InputGroup>
  );
}
