import { Button, Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function () {
  return (
    <Col xs={12} className="mb-3">
      <Link to="/login">
        <Button variant="success" className="py-1 mx-2">
          Login
        </Button>
      </Link>
    </Col>
  );
}
