import { useContext, useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import StudentAccountPage from '../components/accountpage/StudentAccountPage';
import TeacherAccountPage from '../components/accountpage/TeacherAccountPage';
import { AuthContext } from '../context/AuthContextProvider';
import { PageContext } from '../context/PageContextProvider';
import { ADMIN_ROLE, STUDENT_ROLE, TEACHER_ROLE } from '../utils/roleComputation';
import ErrorPlaceholder from '../components/common/ErrorPlaceholder';

const pageMetadata = {
  titleLH: '',
  titleRH: 'Account',
  body: 'Your account details',
};

function AccountPage() {
  const { authRetrievedProfile: auth } = useContext(AuthContext);
  const history = useHistory();
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    if (!auth) history.push('/login');
    setPageMetadata(pageMetadata);
  }, []);

  function DisplaySwitcher() {
    const { role } = auth;

    if (role === ADMIN_ROLE) {
      return <a target="_blank" rel="noreferrer" href="https://www.youtube.com/watch?v=dQw4w9WgXcQ">My Account</a>;
    } if (role === STUDENT_ROLE) {
      return <StudentAccountPage />;
    } if (role === TEACHER_ROLE) {
      return <TeacherAccountPage />;
    }
    return <ErrorPlaceholder />;
  }

  return auth && (
  <>
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
      <Breadcrumb.Item active>Account</Breadcrumb.Item>
    </Breadcrumb>
    <DisplaySwitcher />
  </>
  );
}

export default AccountPage;
