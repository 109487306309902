import { useHistory } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { PageContext } from '../context/PageContextProvider';
import NZPMC24R1 from '../components/forms/templates/students/NZPMC24R1';
import SimpleOnlineWorkshopForm from '../components/forms/templates/students/SimpleOnlineWorkshopForm';
import NZPMC24R2 from '../components/forms/templates/students/NZPMC24R2';

const pageMetadata = {
  titleLH: 'Registration Page for',
  titleRH: 'Event',
  body: 'Fill out the following form and proceed to the next page to make a payment',
};

export default function PaymentPage() {
  const history = useHistory();
  const { eventCode } = history.location.state;
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => {
    pageMetadata.titleRH = `#${eventCode}`;
    setPageMetadata(pageMetadata);
  }, []);

  const formToUse = () => {
    switch (eventCode) {
      case 'NZPMC24R1':
        return <NZPMC24R1 eventCode={eventCode} />;
      case 'NZPMC2024R1SeniorPrepWorkshop':
        return <SimpleOnlineWorkshopForm eventCode={eventCode} time="13/04/2024, Saturday,  19:30 - 21:00 NZT" />;
      case 'NZPMC2024R1JuniorPrepWorkshop':
        return <SimpleOnlineWorkshopForm eventCode={eventCode} time="20/04/2024, Saturday,  17:00 - 18:00 NZT" />;
      case 'NZPMC24R2':
        return <NZPMC24R2 eventCode={eventCode} />;
      default:
        return (
          <>
            Dev Purpose: Form does not exist for
            {eventCode}
            . If you are a user seeing this text, please contact contact@nzpmc.com.
          </>
        );
    }
  };

  return (<>{formToUse()}</>);
}
