import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'react-bootstrap';
import {
  DatePickerInput,
} from '../components/forms/common/FormComponents';
import { AuthContext } from '../context/AuthContextProvider';
import { SessionContext } from '../context/SessionContextProvider';
import { createEvent } from '../services/events';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { PrettyForm } from '../components/forms/common/PrettyForm';
import TextBoxInput from '../components/forms/common/TextBoxInput';
import CheckBoxInput from '../components/forms/common/CheckBoxInput';
import NZTDatePicker from '../components/forms/common/NZTDatePicker';

function CreateEventPage() {
  const { authRetrievedProfile } = useContext(AuthContext);
  const { session } = useContext(SessionContext);
  const history = useHistory();
  const [loading, withLoading] = useWithLoading();

  const onSubmit = () => withLoading(
    createEvent(authRetrievedProfile, session),
    () => {
      alert('Successfully created an event!');
      history.push('/');
    },
    ALERT_ERROR_MESSAGE,
  );

  return (
    <PrettyForm
      submitText="Save"
      onSubmit={onSubmit}
      cancelText="Cancel"
      onCancel={() => history.goBack()}
      loading={loading}
    >
      <h1>
        Create Event
      </h1>
      <p>
        By default:
        <br />
        1.
        {' '}
        <b>Event is unreleased</b>
        : this means it won't be visible to the user until you release it.
        <br />
        2.
        {' '}
        <b>Event access policy is public</b>
        : this means that once Event is released, it will show up in
        everyone's dashboard.
        <br />
        3.
        {' '}
        <b>Event has no form associated with it</b>
        <br />
        4.
        {' '}
        <b>Year 14+ (accounts have roll-over years) are not invited to events</b>
        <br />
        <br />
      </p>
      <TextBoxInput label="Event Code (unique event identifier)" field="eventCode" />
      <TextBoxInput label="Event Name" field="eventName" />
      <TextBoxInput label="Short Description" field="shortDescription" />
      <NZTDatePicker label="Event Date" field="eventDate" />
      <NZTDatePicker label="Registration Due Date" field="registrationDueDate" />
      <TextBoxInput label="Default Event Price (in NZD)" field="defaultPrice" enforceNumeric />
      <CheckBoxInput label="EQI discount enabled" field="isEQIDiscountEnabled" />
      <Alert variant="info">
        <Alert.Heading>What you probably want to do after creating an event</Alert.Heading>
        1. Add Event Access Policy before releasing the event. You can limit students and teachers that can view/register for an event. (requires dev)
        {' '}
        <br />
        2. Add Announcement can be tagged with Event; such Announcement will inherit Event's access policy.
        {' '}
        <br />
        3. Create a form to collect student information before releasing the event. (requires dev)
      </Alert>
    </PrettyForm>
  );
}
export default CreateEventPage;
