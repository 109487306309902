import { useContext, useEffect, useState } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { PageContext } from '../context/PageContextProvider';
import AnnouncementList from '../components/announcement/AnnouncementList';
import { getAllAnnouncements } from '../services/announcement';
import CreateAnnouncementButton from '../components/announcement/CreateAnnouncementButton';
import Loading from '../components/common/Loading';
import { ALERT_ERROR_MESSAGE } from '../utils/ResponseUtils';
import useWithLoading from '../hooks/useWithLoading';
import { AuthContext } from '../context/AuthContextProvider';

const pageMetadata = {
  titleLH: 'Our',
  titleRH: 'Announcements',
  body: "Don't miss any announcements! You need to refresh the page to see new comments.",
};

function AnnouncementListPage() {
  const { setPageMetadata } = useContext(PageContext);
  useEffect(() => setPageMetadata(pageMetadata), []);
  const history = useHistory();

  const [loading, withLoading] = useWithLoading();
  const [announcements, setAnnouncements] = useState();

  const { authRetrievedProfile } = useContext(AuthContext);

  useEffect(() => {
    withLoading(
      () => getAllAnnouncements(authRetrievedProfile?.email, authRetrievedProfile?.code),
      (res) => setAnnouncements(res?.data),
      ALERT_ERROR_MESSAGE,
    );
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item onClick={() => history.push('/')}>Home</Breadcrumb.Item>
        <Breadcrumb.Item active>Announcements</Breadcrumb.Item>
      </Breadcrumb>
      <Loading loading={loading} message="Retrieving all the announcements">
        <AnnouncementList announcements={announcements} />
      </Loading>
      <br />
      <CreateAnnouncementButton />
    </>
  );
}

export default AnnouncementListPage;
