import { Card } from 'react-bootstrap';
import { SchoolIcon } from '../../icons/icons';

function SchoolCard({ teacher }) {
  if (!teacher) return null;
  const schoolName = teacher.homeSchooled === true ? 'School: Homeschooled' : `School: ${teacher.schoolName}`;
  const schoolAddress = (
    <>
      {teacher.schoolAddress}
      <br />

      {teacher.city}
      ,
      {' '}
      {teacher.island}

    </>
  );

  return (
    <Card style={{ boxShadow: '10px 10px', border: '1px solid black' }} className="p-2">
      <span className="text-center">
        <SchoolIcon />
      </span>
      <span className="py-2 text-center">
        <h1 className=" lead d-inline align-middle fw-bold">{schoolName}</h1>
        <h1 className="small">{schoolAddress}</h1>
      </span>
      <div className="py-2 text-center">
        <span>
          🧑‍🏫
          {' Teacher: '}
          {teacher.name}
        </span>
        <br />
        <span>
          📧
          {' Email: '}
          {teacher.email}
        </span>
        <br />
        <span>
          📞
          {' Teacher Contact: '}
          {teacher.phoneNumber}
        </span>
      </div>
    </Card>
  );
}
export default SchoolCard;
