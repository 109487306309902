import Form from 'react-bootstrap/Form';
import CheckBoxInput from './CheckBoxInput';

/**
 * Wraps around CheckBoxInput and creates a bunch of checkboxes
 * @param json json is a map that maps label to field to be used in session (value -> key)
 * @constructor
 */
export function MultiCheckBoxInputFromData({ label, json }) {
  return (
    <>
      <Form.Label className="d-block">{label}</Form.Label>
      {Object.keys(json).map((e) => <CheckBoxInput label={e} field={json[e]} />)}
    </>
  );
}
